import { noawait } from "@smartrr/shared/utils/noawait";
import { z } from "zod";
import { create } from "zustand";
import { cloneDeep, compact, isEqual } from "lodash";
import { useMemo } from "react";
import { ELEMENT_NOT_FOUND } from "@smartrr/shared/constants";
import { persist } from "zustand/middleware";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";

export namespace InteractiveTable {
  export interface DataType {
    id: string;
  }

  export interface SelectedFilter {
    filterType: string;
    value: string[];
  }

  export const sortDirection = ["ASC", "DESC"] as const;
  export const sortSchema = z.enum(sortDirection);
  export type SortDirection = z.infer<typeof sortSchema>;

  export interface SelectedSort {
    sortType: string;
    value: SortDirection;
  }

  export interface SelectedColumn {
    columnType: string;
    value: boolean;
  }

  export interface Pagination {
    /** 1 to maxPages */
    pageNumber: number;
    maxPages: number;
  }
}

interface InteractiveTableStore<
  DataType extends InteractiveTable.DataType,
  FilterType extends InteractiveTable.SelectedFilter,
  ColumnType extends InteractiveTable.SelectedColumn,
  SortType extends InteractiveTable.SelectedSort,
> {
  data: DataType[];
  filters: string[];
  sorts: string[];
  pagination: InteractiveTable.Pagination;

  selectedData: DataType[];
  selectedFilters: FilterType[];
  selectedSort: SortType;
  selectedColumns: ColumnType[];

  isLoading: boolean;

  actions: {
    pagination: {
      firstPage(): void;
      previousPage(): void;
      nextPage(): void;
    };
    filter: {
      update(filter: FilterType[]): void;
      remove(filter: FilterType["filterType"]): void;
      reset(): void;
    };
    sort: {
      select(sort: SortType): void;
    };
    column: {
      update(columns: ColumnType["columnType"][]): void;
    };
    data: {
      updateSelectionById(newSelected: string[]): void;
      update(
        updateDataCallback: (
          currentData: DataType[],
          filters: FilterType[],
          sorts: SortType,
          pagination: InteractiveTable.Pagination
        ) => Promise<{ data: DataType[]; pagination: InteractiveTable.Pagination }>
      ): void;
    };
    whileLoading(loadFn: () => Promise<void>): Promise<void>;
  };

  internal: {
    fetch(): Promise<void>;
    updatePage(pagination: InteractiveTable.Pagination): void;
  };
}

interface CreateInteractiveTableConfig<
  DataType extends InteractiveTable.DataType,
  FilterType extends InteractiveTable.SelectedFilter,
  ColumnType extends InteractiveTable.SelectedColumn,
  SortType extends InteractiveTable.SelectedSort,
  PersistType extends {},
> {
  initialData: DataType[];
  initialFilters: string[];
  initialSorts: string[];
  initialPagination: InteractiveTable.Pagination;
  initialSelectedFilters: FilterType[];
  initialSelectedSort: SortType;
  initialSelectedColumns: ColumnType[];
  persistSettings: {
    name: string;
    version: number;
    partialize: (store: InteractiveTableStore<DataType, FilterType, ColumnType, SortType>) => PersistType;
    migrate: (persistedState: unknown, version: number) => PersistType | undefined;
  };
  onUpdateTable: (
    currentData: DataType[],
    filters: FilterType[],
    sorts: SortType,
    pagination: InteractiveTable.Pagination
  ) => Promise<{ data: DataType[]; maxPages: number }>;
}

export function createInteractiveTable<
  DataType extends InteractiveTable.DataType,
  FilterType extends InteractiveTable.SelectedFilter,
  ColumnType extends InteractiveTable.SelectedColumn,
  SortType extends InteractiveTable.SelectedSort,
  PersistType extends {},
>(config: CreateInteractiveTableConfig<DataType, FilterType, ColumnType, SortType, PersistType>) {
  type StoreType = InteractiveTableStore<DataType, FilterType, ColumnType, SortType>;
  const useInteractiveTable = create<StoreType>()(
    persist(
      (set, get) => ({
        data: config.initialData,
        filters: config.initialFilters,
        sorts: config.initialSorts,
        pagination: config.initialPagination,

        selectedData: [],
        selectedFilters: config.initialSelectedFilters,
        selectedSort: config.initialSelectedSort,
        selectedColumns: config.initialSelectedColumns,

        isLoading: false,

        actions: {
          pagination: {
            firstPage(): void {
              get().internal.updatePage({
                ...get().pagination,
                pageNumber: 1,
              });
            },

            previousPage(): void {
              const pagination = get().pagination;
              if (pagination.pageNumber > 1) {
                get().internal.updatePage({
                  ...pagination,
                  pageNumber: pagination.pageNumber - 1,
                });
              }
            },

            nextPage(): void {
              const pagination = get().pagination;
              if (pagination.pageNumber < pagination.maxPages) {
                get().internal.updatePage({
                  ...pagination,
                  pageNumber: pagination.pageNumber + 1,
                });
              }
            },
          },

          filter: {
            update(updatedFilters: FilterType[]): void {
              const selectedFilters = cloneDeep(get().selectedFilters);

              for (const updatedFilter of updatedFilters) {
                const alreadyPresentFilterIndex = selectedFilters.findIndex(
                  f => f.filterType === updatedFilter.filterType
                );
                if (alreadyPresentFilterIndex === ELEMENT_NOT_FOUND) {
                  selectedFilters.push(updatedFilter);
                } else {
                  selectedFilters[alreadyPresentFilterIndex] = updatedFilter;
                }
              }

              set({ selectedFilters, pagination: { pageNumber: 1, maxPages: get().pagination.maxPages } });
              noawait(async () => await get().internal.fetch());
            },

            remove(filter: FilterType["filterType"]): void {
              const newFilters = cloneDeep(get().selectedFilters).filter(
                selectedFilter => selectedFilter.filterType !== filter
              );
              if (isEqual(newFilters, get().selectedFilters)) {
                return;
              }

              set({
                selectedFilters: newFilters,
                pagination: {
                  pageNumber: 1,
                  maxPages: get().pagination.maxPages,
                },
              });
              noawait(async () => await get().internal.fetch());
            },

            reset(): void {
              set({
                selectedFilters: config.initialSelectedFilters,
                pagination: { maxPages: get().pagination.maxPages, pageNumber: 1 },
              });
              noawait(async () => await get().internal.fetch());
            },
          },

          sort: {
            select(sort: SortType): void {
              set({
                selectedSort: sort,
                pagination: {
                  pageNumber: 1,
                  maxPages: get().pagination.maxPages,
                },
              });
              noawait(async () => await get().internal.fetch());
            },
          },

          column: {
            update(columns: ColumnType["columnType"][]): void {
              const newColumns = cloneDeep(get().selectedColumns);

              for (let index = 0; index < newColumns.length; index++) {
                newColumns[index].value = columns.includes(newColumns[index].columnType);
              }

              set({
                selectedColumns: newColumns,
              });
            },
          },

          data: {
            updateSelectionById(newSelected: string[]): void {
              const selectedResources = get().selectedData;
              const currentData = get().data;
              const newSelectedResources = selectedResources.filter(subscription =>
                newSelected.includes(subscription.id)
              );
              for (const resource of newSelected) {
                const subscription = currentData.find(datum => datum.id === resource);
                const wasResourcePreviouslySelected = selectedResources.some(
                  selectedResouce => selectedResouce.id === resource
                );

                if (subscription && !wasResourcePreviouslySelected) {
                  newSelectedResources.push(subscription);
                }
              }
              set({
                selectedData: newSelectedResources,
              });
            },
            update(
              updateDataCallback: (
                currentData: DataType[],
                filters: FilterType[],
                sorts: SortType,
                pagination: InteractiveTable.Pagination
              ) => Promise<{ data: DataType[]; pagination: InteractiveTable.Pagination }>
            ): void {
              noawait(async () => {
                await get().actions.whileLoading(async () => {
                  const updatedData = await updateDataCallback(
                    get().data,
                    get().selectedFilters,
                    get().selectedSort,
                    get().pagination
                  );

                  set({
                    data: updatedData.data,
                    pagination: updatedData.pagination,
                  });
                });
              });
            },
          },

          async whileLoading(loadfn: () => Promise<void>): Promise<void> {
            set({
              isLoading: true,
            });
            await loadfn();
            set({ isLoading: false });
          },
        },

        internal: {
          async fetch(): Promise<void> {
            await get().actions.whileLoading(async () => {
              const pagination = get().pagination;

              const updatedData = await config.onUpdateTable(
                get().data,
                get().selectedFilters,
                get().selectedSort,
                pagination
              );

              set({
                data: updatedData.data,
                pagination: {
                  pageNumber: pagination.pageNumber,
                  maxPages: updatedData.maxPages,
                },
              });
            });
          },

          updatePage(pagination: InteractiveTable.Pagination): void {
            set({
              pagination,
            });
            noawait(async () => await get().internal.fetch());
          },
        },
      }),
      {
        name: config.persistSettings.name,
        version: config.persistSettings.version,
        partialize: config.persistSettings.partialize,
        migrate: config.persistSettings.migrate,
      }
    )
  );

  type ColumnInfoType = Record<
    ColumnType["columnType"],
    {
      title: string;
    }
  >;

  return {
    useActions() {
      return useInteractiveTable(state => state.actions);
    },
    useIsLoading() {
      return useInteractiveTable(state => state.isLoading);
    },
    useData() {
      return useInteractiveTable(state => state.data);
    },
    useFilters() {
      return useInteractiveTable(state => state.filters);
    },
    useSorts() {
      return useInteractiveTable(state => state.sorts);
    },
    useSelectedData() {
      return useInteractiveTable(state => state.selectedData);
    },
    useSelectedFilters() {
      return useInteractiveTable(state => state.selectedFilters);
    },
    useSelectedFilter(filter: FilterType["filterType"]) {
      const selectedFilters = useInteractiveTable(state => state.selectedFilters);

      return useMemo(() => {
        return selectedFilters.find(selectedFilter => selectedFilter.filterType === filter);
      }, [selectedFilters, filter]);
    },
    useSelectedSort() {
      return useInteractiveTable(state => state.selectedSort);
    },
    useSelectedColumn() {
      return useInteractiveTable(state => state.selectedColumns);
    },
    useEnabledColumns(): ColumnType["columnType"][] {
      const columns = useInteractiveTable(state => state.selectedColumns);

      return useMemo(() => {
        return columns.filter(c => !!c.value).map(c => c.columnType);
      }, [columns]);
    },
    usePagination() {
      return useInteractiveTable(state => state.pagination);
    },
    useIsSelected(id: string): boolean {
      const selected = useInteractiveTable(state => state.selectedData);
      return useMemo(() => {
        return selected.find(datum => datum.id === id) !== undefined;
      }, [selected, id]);
    },
    useTableHeading<ColumnInfo extends ColumnInfoType>(columnInfo: ColumnInfo): NonEmptyArray<IndexTableHeading> {
      const enabledColumns = this.useEnabledColumns();

      return useMemo(() => {
        return compact(
          enabledColumns.map((column): IndexTableHeading | null => {
            if (!columnInfo[column]) {
              return null;
            }
            return {
              id: `table-column-${column}`,
              title: columnInfo[column].title,
            };
          })
        ) satisfies IndexTableHeading[] as unknown as NonEmptyArray<IndexTableHeading>;
      }, [enabledColumns]);
    },

    testing: {
      store: useInteractiveTable,
      actions: useInteractiveTable.getState().actions,
    },
  };
}
